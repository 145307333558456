
import moment from 'moment'

import talks from './talks.js'
import cycles from './cycles.js'
import persons from './persons.js'

import { TimelineScale, TimelineHelper } from '../TimelineTranslator.js'

import styles from '../styles.js'


class DurationEventWithTalks {
  constructor(startDay, endDay) {
    this.startDay = startDay
    this.endDay = endDay
    this.numberOfTalks = 0
  }

  contains(moment) {
    return moment.isBetween(this.startDay, this.endDay, 'day', '[]')
  }

  middle() {
    return moment(this.startDay).add((this.endDay.diff(this.startDay) / 2.0))
  }
}

class NumberOfTalks {
  constructor(date, number) {
    this.date = date
    this.number = number
  }
}

class Cycle extends DurationEventWithTalks {
  constructor(start, end, name) {
    super(start, end)
    this.name = name
  }
}

class Year extends DurationEventWithTalks {
  constructor(startDay) {
    super(startDay, moment(startDay).endOf('year'))
  }
}

class TalkToPersonConnection {
  constructor(talk, person) {
    this.talk = talk
    this.person = person
  }
}


class DataPreparator {

  // wrapperClass is a class reference to any class that has a constructor
  // of the form:
  //
  //    constructor(wrapped, id) { ... }
  //
  // and a method of the form:
  //
  //    connectTo(other)
  //
  // it is used by the preparator to assign ids to the wrapped objects and
  // connect them to other wrapped objects
  constructor(wrapperClass, timelineOptions) {
    this.wrapperClass = wrapperClass

    this.prepareTalks()
    this.preparePersons()
    this.prepareTalkCycles()

    this.prepareScale(timelineOptions)

    this.prepareYears()
  }

  prepareTalks() {
    this.talks = talks
    this.talksWrapped = []
    this.datesWrapped = []
    this.talksWrappedByName = {}

    this.talks.forEach((talk, i) => {
      talk.date = moment(talk.date)

      const wrappedTalk = this.wrapObject(talk, this.componentId("talk", i))
      this.talksWrapped.push(wrappedTalk)

      const wrappedTalkDate = this.wrapObject(talk.date, this.componentId("talk-date", i))
      this.datesWrapped.push(wrappedTalkDate)
      wrappedTalk.connectTo(wrappedTalkDate)

      let talksByName = this.talksWrappedByName[talk.name] || []
      talksByName.push(wrappedTalk)
      this.talksWrappedByName[talk.name] = talksByName
    })
    return talks
  }

  preparePersons() {
    this.persons = persons
    this.personsWrapped = []
    this.talkToPersonConnectionsWrapped = []
    this.personsByName = {}
    this.personsWrappedByName = {}

    let j = 0
    this.persons.forEach((person, i) => {
      this._setColorOnPerson(person, i)

      this.personsByName[person.name] = person
      person.since = moment(person.since)

      const wrappedPerson = this.wrapObject(person, this.componentId("person", i))
      this.personsWrapped.push(wrappedPerson)
      this.personsWrappedByName[person.name] = wrappedPerson

      const wrappedPersonSince = this.wrapObject(person.since,
        this.componentId("person-since", i))
      wrappedPerson.connectTo(wrappedPersonSince)
      this.datesWrapped.push(wrappedPersonSince)

      this.talksWrappedByName[person.name].forEach((talkWrapper) => {
        talkWrapper.connectTo(wrappedPerson)
        wrappedPerson.connectTo(talkWrapper)

        this._prepareTalkToPersonConnection(talkWrapper, wrappedPerson, j++)
      })
    })
  }

  _setColorOnPerson(person, idx) {
    person.color = styles.randomColors[idx % styles.randomColors.length]
  }

  _prepareTalkToPersonConnection(wrappedTalk, wrappedPerson, idx) {
    const connection =
      new TalkToPersonConnection(wrappedTalk.wrapped, wrappedPerson.wrapped)
    const id = this.componentId("talk-person", idx)
    const wrapped = this.wrapObject(connection, id)

    wrappedTalk.connectTo(wrapped)
    wrappedPerson.connectTo(wrapped)

    this.talkToPersonConnectionsWrapped.push(wrapped)
  }

  prepareTalkCycles() {
    this.cycles = cycles.map((c) => {
      return new Cycle(moment(c.start), moment(c.end), c.name)
    })
    this.cyclesWrapped = this.cycles.map((cycle, i) => {
      return this.wrapObject(cycle, this.componentId("cycle", i))
    })

    this._connectDurationEventsToTalks(this.cycles, this.cyclesWrapped, ((wrappedCycle, wrappedTalk) => {
      const wrappedPerson = this.personsWrappedByName[wrappedTalk.wrapped.name]
      if (wrappedPerson) {
        wrappedPerson.connectTo(wrappedCycle)
      }
     }))

    this.wrappedNumbersOfTalksInCycles = this.cyclesWrapped.map((wrappedCycle, i) => {
      const date = wrappedCycle.wrapped.middle()
      const id = this.componentId("cycle-no-of-talks", i)
      return this._prepareWrappedNumberOfTalks(wrappedCycle, date, id)
    })
  }

  _prepareWrappedNumberOfTalks(wrappedDuration, date, id) {
    const noOfTalks = new NumberOfTalks(date, wrappedDuration.wrapped.numberOfTalks)
    const wrappedNumber = this.wrapObject(noOfTalks, id)
    wrappedDuration.connectTo(wrappedNumber)
    return wrappedNumber

  }

  prepareScale(timelineOptions) {
    let startDate = this.firstDate().startOf('month')
    let endDate = this.lastDate().endOf('month')
    this.scale = new TimelineScale(startDate, endDate, timelineOptions)
  }

  prepareYears() {
    const yearDays = TimelineHelper.determineYearLimitDays(this.scale)
    yearDays[0] = this.scale.startMoment

    this.years = yearDays.map((day) => new Year(day))
    this.yearsWrapped = this.years.map((year, i) => {
      return this.wrapObject(year, this.componentId("year", i))
    })
    this._connectDurationEventsToTalks(this.years, this.yearsWrapped)

    this.wrappedNumbersOfTalksInYears = this.yearsWrapped.map((wrappedYear, i) => {
      const date = wrappedYear.wrapped.startDay
      const id = this.componentId("year-no-of-talks", i)
      return this._prepareWrappedNumberOfTalks(wrappedYear, date, id)
    })
  }

  _connectDurationEventsToTalks(durations, durationsWrapped, callback) {
    let durationIdx = 0
    for (let talkIdx = 0; talkIdx < this.talks.length; talkIdx += 1) {
      if (!durations[durationIdx].contains(this.talks[talkIdx].date)) {
        durationIdx += 1
      }
      durationsWrapped[durationIdx].connectTo(this.talksWrapped[talkIdx])
      durations[durationIdx].numberOfTalks += 1
      this.talksWrapped[talkIdx].connectTo(durationsWrapped[durationIdx])

      if (callback) {
        callback(durationsWrapped[durationIdx], this.talksWrapped[talkIdx])
      }
    }
  }

  getPersonByTalk(talk) {
    return this.personsByName[talk.name]
  }

  wrapObject(object, id) {
    return new this.wrapperClass(object, id)
  }

  componentId(string, integer) {
    return `${string}-${integer}`
  }

  firstDate() {
    return moment(this.persons[0].since)
  }

  lastDate() {
    return TimelineHelper.last(
      this.talks[this.talks.length - 1].date,
      this.persons[this.persons.length - 1].since,
      this.cycles[this.cycles.length - 1].endDay)
  }
}

export default DataPreparator
