
import React from 'react'

import IntervalIndicator from './IntervalIndicator.jsx'
import TextIndicator from './TextIndicator.jsx'

import styles from '../styles.js'
import levels from '../levels.js'

class YearIndicator extends React.Component {
  constructor(props) {
    super(props)

    this.textStyle = Object.assign({}, styles.transition, {
      font: '14px sans-serif',
    })

    this.textStyleHighlight = Object.assign({}, this.textStyle, {
      fontWeight: 'bold',
    })
  }

  render() {
    return (
      <g style={{ cursor: 'pointer' }}>
        <IntervalIndicator day={ this.props.year.startDay } length={ levels.yearInterval } />
        <TextIndicator
          y={ levels.yearText }
          day={ this.props.year.startDay }
          style={ this.props.isHighlighted ? this.textStyleHighlight : this.textStyle }
          text={ this.props.year.startDay.format('YYYY') }
        />
      </g>
    )
  }
}


export default YearIndicator

