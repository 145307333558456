
import React from 'react'
import { view } from 'react-easy-state'

import markables from '../markableStore.js'

/**
 * This handles marking a wrapped component on mouse events, by setting the
 * wrapped child components "isHighlighed"-property.
 */
class MarkingWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("Markable updated")
  }

  isHighlighted() {
    return (markables.isTempMarked(this.props.markableId)
        || markables.isMarked(this.props.markableId))
  }

  renderChildren() {
    return React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        isHighlighted: this.isHighlighted()
      })
    })
  }

  handleClick(event) {
    if (this.props.passive) {
      return
    } else {
      event.stopPropagation();
      markables.mark(this.props.markableId)
    }
  }

  render() {
    return (
      <g onClick={ this.handleClick }
         onMouseOver={ (() => this.props.passive || markables.tempMark(this.props.markableId)) }
         onMouseOut={ (() => this.props.passive || markables.removeAllTempMarks()) }>
        {
          this.renderChildren()
        }
      </g>
    )}
}

export default view(MarkingWrapper)
