
import React from 'react';
import { view } from 'react-easy-state'

import MonthsLine from './components/MonthsLine.jsx'
import TalkCycle from './components/TalkCycle.jsx'
import TalkIndicator from './components/TalkIndicator.jsx'
import MarkingWrapper from './components/MarkingWrapper.jsx'
import SimpleDateIndicator from './components/SimpleDateIndicator.jsx'
import PersonIndicator from './components/PersonIndicator.jsx'
import TalkToPersonConnection from './components/TalkToPersonConnection.jsx'
import NumberOfTalksLabel from './components/NumberOfTalksLabel.jsx'

import DataPreparator from './data/DataPreparator.js'
import { TimelineTranslator } from './TimelineTranslator.js'
import markables from './markableStore'
import levels from './levels.js'
import styles from './styles.js'

class Markable {

  constructor(wrapped, id) {
    this.id = id
    this.wrapped = wrapped
    this.isMarked = false
    this.isTempMarked = false
    this.connectsTo = []
  }

  connectTo(markable) {
    this.connectsTo.push(markable)
  }
}

class App extends React.Component {
  constructor() {
    super()

    let timelineOptions = {
      orientation: "horizontal",
      startX: 50,
      startY: 230,
      length: styles.timelineWidth
    }

    this.data = new DataPreparator(Markable, timelineOptions)

    this.scale = this.data.scale

    this.markableTalks = this.data.talksWrapped
    this.markableYears = this.data.yearsWrapped
    this.markableCycles = this.data.cyclesWrapped
    this.markableDates = this.data.datesWrapped
    this.markablePersons = this.data.personsWrapped
    this.markableTalkToPersonConnections = this.data.talkToPersonConnectionsWrapped
    this.markableNumbersOfTalksInCycles = this.data.wrappedNumbersOfTalksInCycles
    this.markableNumbersOfTalksInYears = this.data.wrappedNumbersOfTalksInYears

    markables.addAll(this.markableTalks)
    markables.addAll(this.markableYears)
    markables.addAll(this.markableCycles)
    markables.addAll(this.markableDates)
    markables.addAll(this.markablePersons)
    markables.addAll(this.markableTalkToPersonConnections)
    markables.addAll(this.markableNumbersOfTalksInCycles)
    markables.addAll(this.markableNumbersOfTalksInYears)

    this.batchedMarkablePersons = this.batchPersonsBySinceDate()

    this.cycles = this.data.cycles
  }

  batchPersonsBySinceDate() {
    let batches = []
    let batch = [ this.markablePersons[0] ]
    for (let i = 1; i < this.markablePersons.length; i++) {
      const markable = this.markablePersons[i]
      const lastDate = batch[batch.length - 1].wrapped.since
      if (lastDate.isSame(markable.wrapped.since, 'day')) {
        batch.push(markable)
      } else {
        batches.push(batch)
        batch = [markable]
      }
    }
    batches.push(batch)
    return batches
  }

  applyTimelineTransformation() {
    const translator = new TimelineTranslator('svg-timeline-canvas', this.scale)
    console.log("Applying timeline transformation.")
    translator.translate()
  }

  componentDidMount() {
    this.applyTimelineTransformation()
  }

  deselectAll() {
    markables.removeAllMarks()
  }

  render() {
    return (
      <div onClick={ this.deselectAll }>
        <div style={{ fontFamily: "sans-serif", marginLeft: "50px" }}>
          <h3>
            GUI-Timeline
            &nbsp;
            <span style={ styles.defaultAppearingLabelShown }>
              { this.markableTalks.length } Vorträge! &nbsp;
              { this.markableCycles.length } Zyklusse!
              1 GUI!
            </span>
          </h3>
        </div>

        <br/>



        <div style={ this.containerStyle }>
          <svg version="1.1"
               baseProfile="full"
               width={ styles.timelineWidth + 100} height="2000"
               xmlns="http://www.w3.org/2000/svg"
               id="svg-timeline-canvas">

              <g>
                { this.markableTalkToPersonConnections.map((markable) => (
                  <MarkingWrapper passive={ true } markableId={ markable.id } key={ markable.id }>
                    <TalkToPersonConnection scale={ this.scale } connection={ markable.wrapped } />
                  </MarkingWrapper>
                ))}
              </g>

              { this.batchedMarkablePersons.map((batch) => {
                return batch.map((markable, i) => (
                  <MarkingWrapper markableId={ markable.id } key={ markable.id } >
                    <PersonIndicator
                      y={ levels.persons - ((batch.length - i) * levels.personHeight) }
                      person={ markable.wrapped }
                    />
                  </MarkingWrapper>
                ))
              })}


              { this.markableCycles.map((markable) => (
                  <MarkingWrapper markableId={ markable.id } key={ markable.id } >
                    <TalkCycle cycle={ markable.wrapped } />
                  </MarkingWrapper>
              ))}

              { this.markableNumbersOfTalksInCycles.map((markable) => (
                <MarkingWrapper passive={ true } markableId={ markable.id } key={ markable.id } >
                  <NumberOfTalksLabel numberOfTalks={ markable.wrapped } y={ levels.cycles - 10 } />
                </MarkingWrapper>
              ))}

               <MonthsLine scale={ this.scale } onMark={ this.onMark } markableYearDays={ this.markableYears }/>

               { this.markableNumbersOfTalksInYears.map((markable) => (
                 <MarkingWrapper passive={ true } markableId={ markable.id } key={ markable.id } >
                   <NumberOfTalksLabel numberOfTalks={ markable.wrapped } y={ levels.yearText - 15 } />
                 </MarkingWrapper>
               ))}

              { this.markableDates.map((markable) => (
                <MarkingWrapper passive={ true } markableId={ markable.id } key={ markable.id }>
                  <SimpleDateIndicator date={ markable.wrapped }/>
                </MarkingWrapper>

              ))}

              <g>
                { this.markableTalks.map((markable) => (
                    <MarkingWrapper markableId={ markable.id } key={ markable.id } >
                      <TalkIndicator talk={ markable.wrapped } person={ this.data.getPersonByTalk(markable.wrapped) }/>
                    </MarkingWrapper>
                ))}
              </g>

          </svg>
        </div>

      </div>
    );
  }
}

export default view(App)
