
import { store } from 'react-easy-state'

const markables = store({
  all: {},
  primaryMarkedId: "",
  marked: [],
  tempMarked: [],

  get(id) {
    return this.all[id]
  },

  add(markable) {
    this.all[markable.id] = markable
  },

  addAll(markables) {
    markables.forEach((m) => this.add(m) )
  },

  mark(id) {
    const markable = this.all[id]

    this.removeAllMarks()

    if (!(this.primaryMarkedId === id)) {
      this.primaryMarkedId = id
      this._markOnce(markable.id)
      markable.connectsTo.forEach((m) => this._markOnce(m.id))
    }
  },

  _markOnce(id) {
    const m = this.get(id)
    this.marked.push(m)
    m.isMarked = true
  },

  removeAllMarks() {
    this.marked.forEach((m) => m.isMarked = false)
    this.marked = []
  },

  tempMark(id) {
    const markable = this.get(id)
    this._tempMarkOnce(markable.id)
    markable.connectsTo.forEach((m) => this._tempMarkOnce(m.id))
  },

  _tempMarkOnce(id) {
    const m = this.get(id)
    this.tempMarked.push(m)
    m.isTempMarked = true
  },

  removeAllTempMarks() {
    this.tempMarked.forEach((m) => m.isTempMarked = false)
    this.tempMarked = []
  },

  isMarked(id) {
    return this.get(id).isMarked
  },

  isTempMarked(id) {
    return this.get(id).isTempMarked
  }
})

export default markables
