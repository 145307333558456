
import React from 'react'

import styles from '../styles.js'
import levels from '../levels.js'

import PersonEmoji from './PersonEmoji.jsx'

class TalkIndicator extends React.Component {

  constructor(props) {
    super(props)

    this.textStyle = Object.assign({}, styles.transition, {
      fill: '#a1a1a1',
      font: '11px sans-serif',
    })

    this.highlightTextStyle = Object.assign({}, this.textStyle, {
      fill: 'black',
      fontFace: 'bold',
    })
  }


  render() {
    return (
      <g data-tt-on={ this.props.talk.date.format() }
         style={{ cursor: 'pointer' }}>

        <PersonEmoji person={ this.props.person } y={ levels.talk } isHighlighted={ this.props.isHighlighted } />

        <text x={ 30 } y={ levels.talk - 5 } transform="rotate(55)"
          style={ this.props.isHighlighted ? this.highlightTextStyle : this.textStyle }
        >
          { this.props.talk.title }
        </text>
      </g>
    )
  }
}

export default TalkIndicator