
const colorScheme = {
  // split complementary scheme based on 'one', https://www.colorhexa.com/68aad1
  one: '#68aad1',
  two: '#d1c468',
  three: '#d16876',
  grey: '#a1a1a1'
}

const styles = {
  timelineWidth: 3600,
  colors: {
    cycleBar: colorScheme.one,
    background: colorScheme.grey
  },
  transition: {
    transitionProperty: 'fill fontWeight opacity fontSize',
    transitionDuration: '0.7s',
    transitionTimingFunction: 'ease'
  }
}

// https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/
// minus black, white, magenta, beige
styles.randomColors = shuffle(
                [ '#e6194B', '#3cb44b', '#ffe119', '#4363d8', '#f58231',
                  '#911eb4', '#42d4f4', '#bfef45', '#fabebe', '#469990',
                  '#e6beff', '#9A6324', '#800000', '#aaffc3', '#808000',
                  '#ffd8b1', '#000075', '#a9a9a9',
                  colorScheme.two, colorScheme.three])

styles.defaultAppearingLabelHidden = Object.assign({}, styles.transition, {
  font: '11px sans-serif',
  fill: colorScheme.grey,
  visibility: 'hidden',
  opacity: '0',
})

styles.defaultAppearingLabelShown = Object.assign({},styles.defaultAppearingLabelHidden, {
  visibility: 'visible',
  opacity: '1',
})

function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

export default styles
