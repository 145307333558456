
import React from 'react';

class IntervalIndicator extends React.Component {
  constructor(props) {
    super(props)

    this.stroke = "black"
  }

  render() {
    return (
      <path data-tt-on={ this.props.day.format() }
        stroke={ this.stroke } d={ `M 0 0 V ${this.props.length}` }
      />
    )
  }
}

export default IntervalIndicator