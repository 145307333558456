
import React from 'react';

import TextIndicator from './TextIndicator.jsx'

import styles from '../styles.js'
import levels from '../levels.js'

class TalkCycle extends React.Component {
  constructor(props) {
    super(props)

    this.style = Object.assign({}, styles.transition, {
      opacity: 0.7
    })

    this.highlightStyle = Object.assign({}, this.style, {
      opacity: 1.0
    })
  }

  fontSize(text) {
    // These values work okay with the normal cycle and name lengths, so
    // we just use them instead of computing the actual text width
    var size = 12
    if (text.length > 25) {
      size = 11
    }
    if (text.length > 30) {
      size = 10
    }
    return size
  }

  buildTextStyle(text) {
    const fontSize = this.fontSize(text)
    return {
      font: fontSize.toString() + 'px sans-serif',
      fill: 'white',
      fontWeight: 'bold'
    }
  }

  render() {
    return (
      <g style={{ cursor: 'pointer' }}>
          <rect x={ 0 } y={ levels.cycles }
            style={ this.props.isHighlighted ? this.highlightStyle : this.style }
            width={ 1 } height={ levels.cycleHeight }
            fill={ styles.colors.cycleBar }
            data-tt-start-at={ this.props.cycle.startDay.format() }
            data-tt-end-at={ this.props.cycle.endDay.format() }
          />

          <TextIndicator
            x={ 0 }
            y={ levels.cycles + 12 }
            anchor={ "middle" }
            day={ this.props.cycle.middle() }
            style={ this.buildTextStyle(this.props.cycle.name) }
            text={ this.props.cycle.name }
          />
      </g>

    )
  }
}

export default TalkCycle
