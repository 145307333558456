
import React from 'react'

import styles from '../styles.js'

import PersonEmoji from './PersonEmoji.jsx'

class PersonIndicator extends React.Component {
  constructor(props) {
    super(props)

    this.textStyleNormal = Object.assign({}, styles.transition, {
      font: '12px sans-serif'
    })

    this.textStyleHighlight = Object.assign({}, this.textStyleNormal, {
      fontWeight: 'bold'
    })
  }

  render() {
    return (
      <g data-tt-on={ this.props.person.since.format() } style={{ cursor: 'pointer' }}>

        <PersonEmoji person={ this.props.person } y={ this.props.y } isHighlighted={ this.props.isHighlighted } />

        <text
          x={ 12 }
          y={ this.props.y }
          style={ this.props.isHighlighted ? this.textStyleHighlight : this.textStyleNormal }
        >
          { this.props.person.name }
        </text>
      </g>
    )
  }

}

export default PersonIndicator
