
import moment from 'moment'

const LOG_PREFIX = "TimelineTranslator: "

const TTOrientation = {
  horizontal: 0,
  vertical: 1
}

const TTAttrs = {
  singleEvent: 'data-tt-on',
  oldTransform: 'data-tt-applied-transformation',
  intervalStart: 'data-tt-start-at',
  intervalEnd: 'data-tt-end-at',
  svgTransform: 'transform'
}

class TTPoint {
  constructor(x, y) {
    this.x = x
    this.y = y
  }
}

class TimelineHelper {
  static determineMonthsLimitDays(scale) {
    var days = this.determineLimitDays(scale.startMoment, scale.endMoment, 'month')
    days.push(scale.endMoment)
    return days
  }

  static determineYearLimitDays(scale) {
    let day = moment(scale.startMoment).startOf('year')
    return this.determineLimitDays(day, scale.endMoment, 'year')
  }

  static determineLimitDays(startDay, endDay, unitString) {
    let days = []
    var day = moment(startDay)
    while (day.isBefore(endDay)) {
      days.push(moment(day))
      day.add(1, unitString)
    }
    return days
  }

  static last() {
    let last = arguments[0]
    for (let i = 1; i < arguments.length; i++) {
      if (last.isBefore(arguments[i])) {
        last = arguments[i]
      }
    }
    return last
  }
}


class TimelineScale {

  // startMoment and endMoment are moment objects, options has the form:
  // options = {
  //   orientation: "horizontal",
  //   startX: 0,
  //   startY: 0,
  //   length: 100
  // }
  constructor(startMoment, endMoment, options) {
    this.start = new TTPoint(options.startX, options.startY)
    this.length = options.length
    this.startMoment = startMoment
    this.endMoment = endMoment

    this.useOrientation(options.orientation)
    this.intervals = this.intervalsBetween(startMoment, endMoment)
  }

  useOrientation(orientation) {
    if ("vertical" === orientation) {
      this.orientation = TTOrientation.vertical
    } else {
      this.orientation = TTOrientation.horizontal
    }
  }

  intervalsBetween(start, end) {
    return moment.duration(end.diff(start)).as('seconds')
  }

  calculateOffset(moment) {
    let ratio = this.intervalsBetween(this.startMoment, moment) / this.intervals
    return Math.round(ratio * this.length)
  }

  xPos(moment) {
    let result = this.start.x
    if (this.orientation === TTOrientation.horizontal) {
      result += this.calculateOffset(moment)
    }
    return result
  }

  yPos(moment) {
    let result = this.start.y
    if (this.orientation === TTOrientation.vertical) {
      result += this.calculateOffset(moment)
    }
    return result
  }

  point(moment) {
    return new TTPoint(this.xPos(moment), this.yPos(moment))
  }

  scaleValues(start, end) {
    let newLength = this.calculateOffset(end) - this.calculateOffset(start)
    return {
      x: (this.orientation === TTOrientation.horizontal ? newLength : 1),
      y: (this.orientation === TTOrientation.horizontal ? 1 : newLength)
    }
  }

}


class TimelineTranslator {

  constructor(rootElementId, scale) {
    this.rootElementId = rootElementId
    this.scale = scale
  }

  rootElement() {
    let elem = document.getElementById(this.rootElementId)
    if (!(elem instanceof Element)) {
      console.warn(LOG_PREFIX + "Element with id '" + this.rootElementId + "' not found. Using <body/> instead.")
      return document.body
    }
    return elem
  }

  getEventElements() {
    return this.rootElement().querySelectorAll(`[${TTAttrs.singleEvent}]`)
  }

  getIntervalElements() {
    return this.rootElement().querySelectorAll(`[${TTAttrs.intervalEnd}]`)
  }

  translate() {
    const self = this
    this.getEventElements().forEach(function(elem) {
      new TimelineElement(elem)
          .add(new TimelineTranslation(TTAttrs.singleEvent))
          .transform(self.scale)
    })
    this.getIntervalElements().forEach(function(elem) {
      new TimelineElement(elem)
          .add(new TimelineTranslation(TTAttrs.intervalStart))
          .add(new TimelineLengthScaling(TTAttrs.intervalStart, TTAttrs.intervalEnd))
          .transform(self.scale)
    })
  }
}


class TimelineElement {

  constructor(element) {
    this.elem = element
    this.transformations = []
  }

  transform(scale) {
    let oldString = this.getOldTransformationAttribute()
    this.stripFromTransformAttribute(oldString)

    let newString = this.applyTransformations(scale)
    this.setOrAppendToTransformAttribute(newString)
    this.setOldTransformationAttribute(newString)
  }

  getOldTransformationAttribute() {
    let result = this.elem.getAttribute(TTAttrs.oldTransform) || ""
    return (typeof result === 'string') ? result : ""
  }

  stripFromTransformAttribute(str) {
    let newValue = this.getTransformAttribute().replace(str, "").trim()
    this.setTransformAttribute(newValue)
  }

  getTransformAttribute() {
    let result = this.elem.getAttribute(TTAttrs.svgTransform)
    return (typeof result === 'string') ? result : ""
  }

  setOrAppendToTransformAttribute(str) {
    let value = (this.getTransformAttribute() + " "  + str).trim()
    this.setTransformAttribute(value)
  }

  setTransformAttribute(value) {
    this.elem.setAttribute(TTAttrs.svgTransform, value)
  }

  setOldTransformationAttribute(value) {
    this.elem.setAttribute(TTAttrs.oldTransform, value)
  }

  add(transformation) {
    this.transformations.push(transformation)
    return this
  }

  applyTransformations(scale) {
    return this.transformations
        .map(t => t.transformation(scale, this.elem))
        .join(" ")
  }
}


// TRANSFORMATIONS have the form:
//
// class Transformation {
//   transformation(scale, element) -> String
// }

class TimelineTranslation {

  constructor(attr) {
    this.attr = attr
  }

  transformation(scale, element) {
    let m = moment(element.getAttribute(this.attr))
    return this.translationString(scale, m)
  }

  translationString(scale, moment) {
    let position = scale.point(moment)
    return `translate(${position.x},${position.y})`
  }
}

class TimelineLengthScaling {

  constructor(startAttr, endAttr) {
    this.startAttr = startAttr
    this.endAttr = endAttr
  }

  setIntervalLimitsFromAttributes(element) {
    this.start = moment(element.getAttribute(this.startAttr))
    this.end = moment(element.getAttribute(this.endAttr))
  }

  transformation(scale, element) {
    this.setIntervalLimitsFromAttributes(element)
    return this.scaleString(scale)
  }

  scaleString(scale) {
    let multipliers = scale.scaleValues(this.start, this.end)
    return `scale(${multipliers.x}, ${multipliers.y})`
  }

}

export { TimelineScale, TimelineTranslator, TimelineHelper }
