

export default [
    {
    "name": "Simon",
    "since": "2013-08-18",
    "emoji": "🦉"
  },
  {
    "name": "Elfchen",
    "since": "2013-08-18",
    "emoji": "🍸"
  },
  {
    "name": "Lydia",
    "since": "2013-08-18",
    "emoji": "🦖"
  },
  {
    "name": "Joel",
    "since": "2013-08-18",
    "emoji": "🌵"
  },
  {
    "name": "Vio",
    "since": "2013-08-18",
    "emoji": "🦌"
  },
  {
    "name": "Schörnchen",
    "since": "2013-08-18",
    "emoji": "🐈"
  },
  {
    "name": "David",
    "since": "2013-10-27",
    "emoji": "🍰"
  },
  {
    "name": "Me",
    "since": "2014-07-04",
    "emoji": "🐉"
  },
  {
    "name": "Anna",
    "since": "2014-11-09",
    "emoji": "🤣"
  },
  {
    "name": "Manuel",
    "since": "2014-11-09",
    "emoji": "🎻"
  },
  {
    "name": "Alex",
    "since": "2015-11-22",
    "emoji": "🤔"
  },
  {
    "name": "Romina",
    "since": "2016-04-24",
    "emoji": "🌄"
  },
  {
    "name": "Matthias",
    "since": "2017-02-11",
    "emoji": "🎉"
  }
]