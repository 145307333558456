
import React from 'react'

import IntervalIndicator from './IntervalIndicator.jsx'
import MarkingWrapper from './MarkingWrapper.jsx'
import YearIndicator from './YearIndicator.jsx'

import levels from '../levels.js'

import { TimelineHelper } from '../TimelineTranslator.js'

class MonthsLine extends React.Component {
  constructor(props) {
    super(props)

    this.start = props.start
    this.length = props.length

    this.scale = props.scale

    this.monthLimitDays = TimelineHelper.determineMonthsLimitDays(this.scale)
  }

  render() {
    return (
      <g>
          <rect x={0} y={0} width={1} height={1} fill={'black'}
            data-tt-start-at={ this.scale.startMoment.format() }
            data-tt-end-at={ this.scale.endMoment.format() } />

          <g>
            { this.monthLimitDays.map((day, i) => <IntervalIndicator day={ day } length={ levels.monthInterval } key={ i } />) }
          </g>

          <g>
            { this.props.markableYearDays.map((m, i) =>
              <MarkingWrapper markableId={ m.id } key={ m.id } >
                <YearIndicator year={ m.wrapped } />
              </MarkingWrapper>

              )
            }
          </g>
      </g>
    )
  }
}

export default MonthsLine
