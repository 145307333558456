
import React from 'react'

import styles from '../styles.js'
import levels from '../levels.js'

class TalkToPersonConnection extends React.Component {
  constructor(props) {
    super(props)

    this.person = this.props.connection.person
    this.talk = this.props.connection.talk

    this.normalStyle = Object.assign({}, styles.transition, {
      stroke: this.props.connection.person.color,
      fill: 'transparent',
      visibility: 'hidden',
      opacity: '0',
    })

    this.highlightStyle = Object.assign({}, this.normalStyle, {
      visibility: 'visible',
      opacity: '0.8'
    })

    this.curveCommand = this.generateCurveCommand()
  }

  generateCurveCommand() {
    let start = {
      x: 0,
      y: levels.persons - levels.personHeight
    }
    let end = {
      x: this.removeTimelineTranslationOffset(this.props.scale.xPos(this.talk.date)),
      y: levels.cycles
    }
    let diff = this.positionDiff(start, end)
    let control = {
      x: start.x,
      y: start.y + (diff.y / 2.1)
    }
    let mid = {
      x: start.x + (diff.x / 2),
      y: start.y + (diff.y / 2)
    }
    let result =  `M ${start.x} ${start.y} `
                + `Q ${control.x} ${control.y} ${mid.x} ${mid.y} `
                + `T ${end.x} ${end.y} `
                + "V 0";
    return result
  }

  removeTimelineTranslationOffset(xPos) {
    return xPos - this.props.scale.xPos(this.person.since)
  }

  positionDiff(pos1, pos2) {
    return {
      x: Math.abs(pos1.x - pos2.x),
      y: Math.abs(pos1.y - pos2.y),
    }
  }

  render() {
    return (
      <path data-tt-on={ this.person.since.format() }
        style={ this.props.isHighlighted ? this.highlightStyle : this.normalStyle }
        d={ `${this.curveCommand}` }
      />
    )
  }
}

export default TalkToPersonConnection

