
import React from 'react'

import TextIndicator from './TextIndicator.jsx'

import styles from '../styles.js'

class SimpleDateIndicator extends React.Component {
  constructor(props) {
    super(props)

    this.normalStyle = styles.defaultAppearingLabelHidden
    this.highlightStyle = styles.defaultAppearingLabelShown
  }

  render() {
    return (
      <TextIndicator
        y={ -10 }
        day={ this.props.date }
        style={ this.props.isHighlighted ? this.highlightStyle : this.normalStyle }
        text={ this.props.date.format("DD.MM.YYYY") }
      />
    )
  }

}

export default SimpleDateIndicator
