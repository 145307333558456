export default [
  {
    "date": "2013-09-01T00:00:00+00:00",
    "name": "Simon",
    "title": "Nietzsches Über Wahrheit und Lüge im außermoralischen Sinne"
  },
  {
    "date": "2013-09-15T00:00:00+00:00",
    "name": "Elfchen",
    "title": "Weiblichkeitsentwürfe in der Kunst der neue Sachlichkeit"
  },
  {
    "date": "2013-10-05T00:00:00+00:00",
    "name": "Lydia",
    "title": "Tod des Autors am Beispiel Lolita von Nabokov"
  },
  {
    "date": "2013-10-13T00:00:00+00:00",
    "name": "Joel",
    "title": "Kurzgeschichte Neumarkt_09.17.2013.sav"
  },
  {
    "date": "2013-11-10T00:00:00+00:00",
    "name": "Vio",
    "title": "Epigenetik und klassische Genetik"
  },
  {
    "date": "2013-11-24T00:00:00+00:00",
    "name": "Schörnchen",
    "title": "Kultur der Ureinwohner Neuguineas"
  },
  {
    "date": "2013-12-08T00:00:00+00:00",
    "name": "Joel",
    "title": "Sinn und Bedeutung in Kommunikation - Der Ansatz von Grice"
  },
  {
    "date": "2013-12-22T00:00:00+00:00",
    "name": "David",
    "title": "Der Demeter-Hymnos - Ein Einblick etc"
  },
  {
    "date": "2014-01-12T00:00:00+00:00",
    "name": "Simon",
    "title": "Oscar Wilde - Die Seele unter dem Sozialismus"
  },
  {
    "date": "2014-01-25T00:00:00+00:00",
    "name": "Joel",
    "title": "Fünf häufig missbrauchte Drogen"
  },
  {
    "date": "2014-02-09T00:00:00+00:00",
    "name": "Elfchen",
    "title": "Völkerschauen im 19. Jahrhundert"
  },
  {
    "date": "2014-02-23T00:00:00+00:00",
    "name": "David",
    "title": "Gottlob Frege und der Beginn der Sprachphilosophie"
  },
  {
    "date": "2014-03-09T00:00:00+00:00",
    "name": "Simon",
    "title": "Die Bedeutung der Krim - Historischer Blick und aktuelle Situation"
  },
  {
    "date": "2014-03-30T00:00:00+00:00",
    "name": "Schörnchen",
    "title": "Die Viele-Welten Interpretation"
  },
  {
    "date": "2014-04-27T00:00:00+00:00",
    "name": "Lydia",
    "title": "Geschlechtskrankheiten"
  },
  {
    "date": "2014-05-11T00:00:00+00:00",
    "name": "Vio",
    "title": "Astrobiologie I"
  },
  {
    "date": "2014-06-01T00:00:00+00:00",
    "name": "Elfchen",
    "title": "Haarkunst"
  },
  {
    "date": "2014-06-16T00:00:00+00:00",
    "name": "David",
    "title": "David Foster Wallace über Fernsehen und U.S. Literatur"
  },
  {
    "date": "2014-08-10T00:00:00+00:00",
    "name": "Simon",
    "title": "Von den Dark Ages in die Archaik"
  },
  {
    "date": "2014-08-24T00:00:00+00:00",
    "name": "Me",
    "title": "Nahostkonflikt - Entstehung und Bedeutung"
  },
  {
    "date": "2014-09-21T00:00:00+00:00",
    "name": "Schörnchen",
    "title": "Religiöse und politische Gruppierungen im Nahen Osten"
  },
  {
    "date": "2014-09-28T00:00:00+00:00",
    "name": "Lydia",
    "title": "Karma und Reinkarnation"
  },
  {
    "date": "2014-10-12T00:00:00+00:00",
    "name": "Vio",
    "title": "Ritueller Missbrauch in Sekten"
  },
  {
    "date": "2014-10-26T00:00:00+00:00",
    "name": "Joel",
    "title": "Emotionen... Versuch einer Übersicht"
  },
  {
    "date": "2014-11-30T00:00:00+00:00",
    "name": "Elfchen",
    "title": "Anatomiedarstellung in der Kunst"
  },
  {
    "date": "2014-12-14T00:00:00+00:00",
    "name": "Anna",
    "title": "Gewaltfreie Kommunikation nach Marshall B. Rosenberg"
  },
  {
    "date": "2014-12-28T00:00:00+00:00",
    "name": "David",
    "title": "Welthunger - Einige Fakten"
  },
  {
    "date": "2015-01-11T00:00:00+00:00",
    "name": "Simon",
    "title": "Geschichte der Klassik am Beispiel von Athen und Sparta"
  },
  {
    "date": "2015-02-08T00:00:00+00:00",
    "name": "Schörnchen",
    "title": "Grundlagen der traditionellen chinesischen Medizin"
  },
  {
    "date": "2015-02-22T00:00:00+00:00",
    "name": "Me",
    "title": "Ausdehnung des Universums"
  },
  {
    "date": "2015-03-08T00:00:00+00:00",
    "name": "Vio",
    "title": "Cholera"
  },
  {
    "date": "2015-03-22T00:00:00+00:00",
    "name": "Lydia",
    "title": "Tolstoj - Teil 1"
  },
  {
    "date": "2015-04-12T00:00:00+00:00",
    "name": "Joel",
    "title": "Bilderleben -  Zwei KInder mit einem Lamm"
  },
  {
    "date": "2015-04-26T00:00:00+00:00",
    "name": "Manuel",
    "title": "Musiktherapie - eine Einführung"
  },
  {
    "date": "2015-05-24T00:00:00+00:00",
    "name": "Anna",
    "title": "Bedingsungsloses Grundeinkommen"
  },
  {
    "date": "2015-06-21T00:00:00+00:00",
    "name": "Elfchen",
    "title": "Gotik - Der Kölner Dom 1"
  },
  {
    "date": "2015-06-28T00:00:00+00:00",
    "name": "David",
    "title": "Macht, Institutionen, Wohlstand und Armut"
  },
  {
    "date": "2015-07-12T00:00:00+00:00",
    "name": "Simon",
    "title": "Die Greichen III – Alexander der Große und der Beginn des Hellenismus"
  },
  {
    "date": "2015-07-26T00:00:00+00:00",
    "name": "Joel",
    "title": "Lernen und Gedächtnis"
  },
  {
    "date": "2015-09-20T00:00:00+00:00",
    "name": "Vio",
    "title": "Grüne Gentechnik"
  },
  {
    "date": "2015-10-11T00:00:00+00:00",
    "name": "Lydia",
    "title": "Die Frühen Literarischen Werke und der realistische Roman – Tolstoj Teil II"
  },
  {
    "date": "2015-10-25T00:00:00+00:00",
    "name": "David",
    "title": "Wissenschaftstheorie - Ein Überblick"
  },
  {
    "date": "2015-11-08T00:00:00+00:00",
    "name": "Anna",
    "title": "Mechanische Reanimation"
  },
  {
    "date": "2015-11-22T00:00:00+00:00",
    "name": "Manuel",
    "title": "Musiktherapeutische Forschung"
  },
  {
    "date": "2015-12-20T00:00:00+00:00",
    "name": "Simon",
    "title": "Einführung in die Konfliktsimulation am Beispiel von Labyrinth von Volko Ruhnke (GMT Games)"
  },
  {
    "date": "2016-01-03T00:00:00+00:00",
    "name": "Joel",
    "title": "Hypnose"
  },
  {
    "date": "2016-01-17T00:00:00+00:00",
    "name": "Joel",
    "title": "Pseudologia Phantastica"
  },
  {
    "date": "2016-01-31T00:00:00+00:00",
    "name": "David",
    "title": "Planung von Softwareentwicklung"
  },
  {
    "date": "2016-03-13T00:00:00+00:00",
    "name": "Me",
    "title": "Flüchtlinge - Was machen sie mit uns"
  },
  {
    "date": "2016-05-08T00:00:00+00:00",
    "name": "Alex",
    "title": "Civil Rights Movement"
  },
  {
    "date": "2016-05-22T00:00:00+00:00",
    "name": "Lydia",
    "title": "Veganismus - Eine ernährungsphysiologische Einführung"
  },
  {
    "date": "2016-06-05T00:00:00+00:00",
    "name": "Simon",
    "title": "Nietzsches Antichrist"
  },
  {
    "date": "2016-07-17T00:00:00+00:00",
    "name": "Anna",
    "title": "Leibesphänonenologie"
  },
  {
    "date": "2016-09-25T00:00:00+00:00",
    "name": "Manuel",
    "title": "Musikalische Entspannung"
  },
  {
    "date": "2016-10-09T00:00:00+00:00",
    "name": "David",
    "title": "Lüge Humbug Bullshit"
  },
  {
    "date": "2016-10-23T00:00:00+00:00",
    "name": "Anna",
    "title": "Übungssetting Gewaltfreie Kommunikation"
  },
  {
    "date": "2016-11-06T00:00:00+00:00",
    "name": "Simon",
    "title": "Die röm. Rebublik und der Hellenismus"
  },
  {
    "date": "2016-12-04T00:00:00+00:00",
    "name": "Manni",
    "title": "Gastvortrag Feldenkrais"
  },
  {
    "date": "2017-02-12T00:00:00+00:00",
    "name": "Alex",
    "title": "Männlichkeitskonstruktion: Dude, You're a Fag"
  },
  {
    "date": "2017-02-26T00:00:00+00:00",
    "name": "David",
    "title": "England regieren for Dummies - 1485 Edition - Teil 1"
  },
  {
    "date": "2017-03-12T00:00:00+00:00",
    "name": "Vio",
    "title": "Gustav Mahlers 7. Symphonie in ihrer Zeit"
  },
  {
    "date": "2017-04-09T00:00:00+00:00",
    "name": "Manuel",
    "title": "Der Wert des Lebens mit Krankheit, Leid und Tod"
  },
  {
    "date": "2017-05-07T00:00:00+00:00",
    "name": "Romina",
    "title": "Peru : Inca"
  },
  {
    "date": "2017-05-21T00:00:00+00:00",
    "name": "Me",
    "title": "Men's Rights Movement"
  },
  {
    "date": "2017-06-04T00:00:00+00:00",
    "name": "Joel",
    "title": "Einführung in die Grundlagen der Statistik"
  },
  {
    "date": "2017-06-18T00:00:00+00:00",
    "name": "Simon",
    "title": "Where no one has gone before – Ein Einblick in die bemannte Raumfahrt"
  },
  {
    "date": "2017-07-02T00:00:00+00:00",
    "name": "Matthias",
    "title": "Cradle to Cradle"
  },
  {
    "date": "2017-07-16T00:00:00+00:00",
    "name": "Joel",
    "title": "Unser Gehirn"
  },
  {
    "date": "2017-08-13T00:00:00+00:00",
    "name": "Vio",
    "title": "Der Vampir-Mythos"
  },
  {
    "date": "2017-09-10T00:00:00+00:00",
    "name": "Simon",
    "title": "Glück"
  },
  {
    "date": "2017-10-08T00:00:00+00:00",
    "name": "Alex",
    "title": "Bedingungen eines gelungenen Zusammenlebens in Gruppen"
  },
  {
    "date": "2017-11-05T00:00:00+00:00",
    "name": "Me",
    "title": "Sexualstraftäter"
  },
  {
    "date": "2017-11-19T00:00:00+00:00",
    "name": "Manuel",
    "title": "OPD"
  },
  {
    "date": "2018-01-14T00:00:00+00:00",
    "name": "Matthias",
    "title": "Stadtentwicklung : Köln : Zukunft?"
  },
  {
    "date": "2018-03-11T00:00:00+00:00",
    "name": "David",
    "title": "Wie das Internet funktioniert"
  },
  {
    "date": "2018-04-08T00:00:00+00:00",
    "name": "Joel",
    "title": "Meditation und Achtsamkeit"
  },
  {
    "date": "2018-04-22T00:00:00+00:00",
    "name": "Lydia",
    "title": "Resilienz"
  },
  {
    "date": "2018-05-06T00:00:00+00:00",
    "name": "Simon",
    "title": "Wissenschaft und Spiritualität"
  },
  {
    "date": "2018-05-20T00:00:00+00:00",
    "name": "Bobby",
    "title": "Gastvortrag Online-Politmagazin Sparrow"
  },
  {
    "date": "2018-06-03T00:00:00+00:00",
    "name": "David",
    "title": "Wittgensteins Philosophische Untersuchungen – Einige wichtige Ideen"
  },
  {
    "date": "2018-07-01T00:00:00+00:00",
    "name": "Alex",
    "title": "Einführung in die Trainingslehre"
  },
  {
    "date": "2018-07-15T00:00:00+00:00",
    "name": "Me",
    "title": "Intuition"
  },
  {
    "date": "2018-07-29T00:00:00+00:00",
    "name": "Matthias",
    "title": "Stadtentwicklung : Auto : Fahrrad?"
  },
  {
    "date": "2018-08-26T00:00:00+00:00",
    "name": "Anna",
    "title": "Die Entwicklung des Babys Leonie"
  },
  {
    "date": "2018-09-09T00:00:00+00:00",
    "name": "Lydia",
    "title": "Das Ehepaar Beltracchi – Der erigierte Penis des Kunstmarktes"
  },
  {
    "date": "2018-11-18T00:00:00+00:00",
    "name": "David",
    "title": "Der Theseus Mythos"
  },
  {
    "date": "2018-12-02T00:00:00+00:00",
    "name": "Simon",
    "title": "Restoring Pride"
  },
  {
    "date": "2019-01-13T00:00:00+00:00",
    "name": "Alex",
    "title": "Überwachen und Strafen - institutionalisierte Gewalt in einer Wohngruppe der Jugendhilfe"
  },
  {
    "date": "2019-02-10T00:00:00+00:00",
    "name": "Lydia",
    "title": "Jean Dubuffet und l'art brut"
  },
  {
    "date": "2019-02-24T00:00:00+00:00",
    "name": "Matthias",
    "title": "Brutalismus"
  },
  {
    "date": "2019-03-10T00:00:00+00:00",
    "name": "Vio",
    "title": "Menschliche Riechstoffe"
  },
  {
    "date": "2019-03-24T00:00:00+00:00",
    "name": "Anna",
    "title": "Coolout - Von der Kälte in sozialen Berufen"
  },
  {
    "date": "2019-05-05T00:00:00+00:00",
    "name": "Me",
    "title": "Volt Europa"
  },
  {
    "date": "2019-05-19T00:00:00+00:00",
    "name": "Joel",
    "title": "Morphologie"
  },
  {
    "date": "2019-06-02T00:00:00+00:00",
    "name": "Manuel",
    "title": "Die kleine Weinverkostung (Praktische Önologie)"
  },
  {
    "date": "2019-09-09T00:00:00+00:00",
    "name": "David",
    "title": "Einige Fakten über das Mittelalter etc."
  },
  {
    "date": "2019-09-22T00:00:00+00:00",
    "name": "Joel",
    "title": "Verschwörungstheorien"
  }
]
