

const levels = {

  // how items are arranged above (-) and below (+) the timeline
  persons: -120,
  cycles: -80,
  yearText: -25,
  yearInterval: -20,
  monthInterval: 10,
  talk: 20,

  // how thick these items are drawn
  personHeight: 15,
  cycleHeight: 15,

}

export default levels
