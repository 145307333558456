
import React from 'react';

class TextIndicator extends React.Component {
  constructor(props) {
    super(props)

    this.x = props.x || 0
    this.anchor = props.anchor || "middle"
  }
  render() {
    return (
      <g data-tt-on={ this.props.day.format() }>
        <text x={ this.x } y={ this.props.y } textAnchor={ this.anchor } style={ this.props.style } >
          { this.props.text }
        </text>
      </g>
    )
  }
}

export default TextIndicator