
import React from 'react';

import TextIndicator from './TextIndicator.jsx'

import styles from '../styles.js'

class NumberOfTalksLabel extends React.Component {
  render() {
    return (
      <TextIndicator
        y={ this.props.y }
        day={ this.props.numberOfTalks.date }
        style={ this.props.isHighlighted ? styles.defaultAppearingLabelShown : styles.defaultAppearingLabelHidden }
        text={ `${this.props.numberOfTalks.number} Vorträge` }
      />
    )
  }
}

export default NumberOfTalksLabel
