
import React from 'react'

import styles from '../styles.js'

class PersonEmoji extends React.Component {
  constructor(props) {
    super(props)

    this.emojiStyle = Object.assign({}, styles.transition, {
      fontFamily: 'Segoe UI Emoji',
      fontSize: '14px',
      opacity: 0.7,
    })

    this.emojiStyleHighlight = Object.assign({}, this.emojiStyle, {
      fontSize: '18px',
      opacity: 1,
    })

    this.person = this.props.person || { emoji: "" }
  }

  render() {
    return (
        <text x={ 0 } y={ this.props.y }
          textAnchor="middle"
          style={ this.props.isHighlighted ? this.emojiStyleHighlight : this.emojiStyle }
        >
          { this.person.emoji }
        </text>
    )
  }
}

export default PersonEmoji

