
export default [
  {
    "name": "Zyklus 1",
    "start": "2013-09-01",
    "end": "2013-12-22"
  },
  {
    "name": "Zyklus 2",
    "start": "2014-01-12",
    "end": "2014-05-11"
  },
  {
    "name": "Zyklus 3",
    "start": "2014-06-01",
    "end": "2014-11-09"
  },
  {
    "name": "Zyklus 4",
    "start": "2014-11-30",
    "end": "2015-05-10"
  },
  {
    "name": "Zyklus 5",
    "start": "2015-05-24",
    "end": "2015-08-09"
  },
  {
    "name": "Zyklus 6",
    "start": "2015-09-20",
    "end": "2016-01-03"
  },
  {
    "name": "Zyklus 7",
    "start": "2016-01-17",
    "end": "2016-10-23"
  },
  {
    "name": "Zyklus 8",
    "start": "2016-11-06",
    "end": "2017-07-16"
  },
  {
    "name": "Zyklus 9",
    "start": "2017-08-13",
    "end": "2018-03-11"
  },
  {
    "name": "Zyklus 10",
    "start": "2018-04-08",
    "end": "2018-09-09"
  },
  {
    "name": "Zyklus des verwirrten Elefanten",
    "start": "2018-11-18",
    "end": "2019-06-03"
  },
  {
    "name": "Z. d. enigmatischen Schmetterlings",
    "start": "2019-09-09",
    "end": "2020-01-12"
  },
]
